import { CertificateForm } from "@/components/Pages/Argentina/CertificateForm";
import Layout from "@/components/Pages/Argentina/Layout";

export default function HomePage() {
  return (
    <Layout country="ar">
      <CertificateForm />
    </Layout>
  );
}
