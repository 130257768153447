import { ReactNode } from "react";
import { UseFormRegister } from "react-hook-form";

import { Input } from "@/components/Form/Input";
import SpinnerIcon from "@/components/Icons/SpinnerIcon";

const InputField = ({
  label,
  errorMessage,
  register,
  loading,
  required,
  ...inputProps
}: {
  label?: ReactNode;
  errorMessage?: string;
  register?: UseFormRegister<any>;
  required?: boolean;
  loading?: boolean;
  [x: string]: any;
}) => {
  return (
    <div className="relative">
      {label && (
        <p className="mb-1 text-xs font-medium text-blueGray600">
          {label}

          {required && <span className="ml-1 text-red500">*</span>}
        </p>
      )}
      <Input
        fluid
        size="REGULAR"
        textSize="BASE"
        register={register}
        errorMessage={errorMessage}
        {...inputProps}
      />

      {loading && (
        <div className="absolute flex items-center justify-center right-2 top-7">
          <SpinnerIcon size={24} />
        </div>
      )}
    </div>
  );
};

export default InputField;
