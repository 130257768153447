import JSZip from "jszip";
import forge from "node-forge";

export const GenerateCSRAndPrivateKeyArchive = async (taxNumber: string, companyName: string) => {
  const keys = forge.pki.rsa.generateKeyPair(2048);

  //    node-forge by default exports a PKCS#1 PEM when calling privateKeyToPem().
  //    To get a PKCS#8 format, we first convert the key to an ASN.1 structure, then
  //    encode it in PEM with appropriate PKCS#8 headers.
  const pkcs8Asn1 = forge.pki.privateKeyToAsn1(keys.privateKey);
  const pkcs8Pem = forge.pki.privateKeyInfoToPem(pkcs8Asn1);

  taxNumber = taxNumber.replaceAll("-", "");

  const csr = forge.pki.createCertificationRequest();
  csr.publicKey = keys.publicKey;
  csr.setSubject([
    { name: "countryName", value: "AR" },
    { name: "organizationName", value: companyName },
    { name: "commonName", value: "Fonoa" },
    { name: "serialNumber", value: `CUIT ${taxNumber}` },
  ]);

  csr.sign(keys.privateKey, forge.md.sha256.create());
  const csrPem = forge.pki.certificationRequestToPem(csr);

  const zip = new JSZip();
  zip.file("private_key.pem", pkcs8Pem);
  zip.file("certificate_request.csr", csrPem);

  const zipBlob = await zip.generateAsync({ type: "blob" });

  return zipBlob;
};
