"use client"; //ensure CSR and private key are generated on client side only

import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { toast } from "react-toastify";
import * as yup from "yup";

import Button from "@/components/Form/Button";
import InputField from "@/components/Form/InputField/InputField";
import { GenerateCSRAndPrivateKeyArchive } from "@/components/Pages/Argentina/CSRGenerator";
import Toast from "@/components/Toast/Toast";
import { extractYupErrorKeys } from "@/utils/extract-yup-error-keys";

interface CertificateFormProps {
  taxNumber?: string;
  companyName?: string;
  disabled?: boolean;
}

const formErrorMessages = defineMessages({
  TAXNUMBER_IS_REQUIRED: {
    defaultMessage: "Tax Number is required",
    id: "sLP5Tf",
    description: "Argentina > Tax number is required error message",
  },
  TAXNUMBER_FORMAT: {
    defaultMessage: "Tax Number format should be ##-12345678-X or ##12345678X",
    id: "5NMAWd",
    description: "Argentina > Tax number format message",
  },
  COMPANYNAME_IS_REQUIRED: {
    defaultMessage: "Company name is required",
    id: "faO56z",
    description: "Argentina > Company name is required error message",
  },
});

const FORM_ERROR_KEYS = extractYupErrorKeys(formErrorMessages);

const schema = yup
  .object({
    taxNumber: yup
      .string()
      .required(FORM_ERROR_KEYS.TAXNUMBER_IS_REQUIRED)
      .matches(/^(?:\d{11}|\d{2}-\d{8}-\d{1})$/, FORM_ERROR_KEYS.TAXNUMBER_FORMAT),
    companyName: yup.string().required(FORM_ERROR_KEYS.COMPANYNAME_IS_REQUIRED),
  })
  .required();

export function CertificateForm(props: CertificateFormProps) {
  const intl = useIntl();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      taxNumber: props.taxNumber,
      companyName: props.companyName,
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (data: any) => {
    toast.dismiss();
    setIsSubmitting(true);

    try {
      const zipBlob = await GenerateCSRAndPrivateKeyArchive(data.taxNumber, data.companyName);

      downloadBlob(zipBlob);

      setIsSubmitting(false);

      Toast(
        <FormattedMessage
          defaultMessage="Certificate request successfully generated!"
          id="+YM/Sx"
          description="Argentina > CSR generation success message"
        />,
        {
          type: "success",
        }
      );
    } catch (error) {
      console.log(error);
      Toast(
        <FormattedMessage
          defaultMessage="There was an error generating a certificate request."
          id="ND1Yph"
          description="Argentina > error generating CSR message"
        />,
        {
          type: "error",
        }
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <h1 className="text-xl font-medium text-center text-primaryBlue900">
        <FormattedMessage
          defaultMessage="Get your CSR certificate"
          id="eCCzbd"
          description="Argentina > CSR certificate title"
        />
      </h1>
      <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
        <div className="text-xs">
          <FormattedMessage
            defaultMessage="Generate Digital Certificate request for AFIP."
            id="F6xDJA"
            description="Argentina > Introduction"
          />
        </div>
        <div className="w-full space-y-6 mt-6">
          <InputField
            label={intl.formatMessage({
              defaultMessage: "Tax Number (CUIT)",
              id: "D4Ak9q",
              description: "Argentina > Tax Number label",
            })}
            placeholder="99-99999999-9 | 99999999999"
            data-cy="txt-tax-number"
            register={register}
            disabled={isSubmitting || props.disabled}
            name="taxNumber"
            errorMessage={
              errors.taxNumber?.message && formErrorMessages[errors.taxNumber.message]
                ? intl.formatMessage(formErrorMessages[errors.taxNumber.message])
                : errors.taxNumber?.message
            }
            required
          />
        </div>
        <div className="w-full space-y-6 mt-6">
          <InputField
            label={intl.formatMessage({
              defaultMessage: "Company name",
              id: "j4D4e9",
              description: "Argentina > Company name label",
            })}
            data-cy="txt-company-name"
            register={register}
            disabled={isSubmitting || props.disabled}
            name="companyName"
            errorMessage={
              errors.companyName?.message && formErrorMessages[errors.companyName.message]
                ? intl.formatMessage(formErrorMessages[errors.companyName.message])
                : errors.companyName?.message
            }
            required
          />
        </div>
        <Button
          type="submit"
          fluid
          className="mt-8"
          variant="FILLED"
          data-cy="btn-submit"
          disabled={isSubmitting || props.disabled}
        >
          <FormattedMessage
            defaultMessage="Submit"
            id="4LlUeh"
            description="Argentina > Submit button label"
          />
        </Button>
      </form>
    </div>
  );
}

function downloadBlob(zipBlob: Blob) {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(zipBlob);
  link.download = "certificate_request.zip";
  link.click();
  link.remove();
}
