import classNames from "classnames";
import Head from "next/head";
import { ReactNode, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { getLanguageByCountry } from "@/client/utils";
import Logo from "@/components/Logo";
import { Language } from "@/components/Types";
import A from "@/components/Typography/A";
import CountryFlagLabel from "@/components/Utils/CountryFlagLabel";
import { useSelectedLanguage } from "@/hooks/useSelectedLanguage";

interface LayoutProps {
  windowTitle?: string;
  children: ReactNode;
  country?: string;
}

export default function Layout({ windowTitle = "Fonoa", children, country }: LayoutProps) {
  const { setSelectedLanguage } = useSelectedLanguage();
  const countryLanguage = getLanguageByCountry(country);

  useEffect(() => {
    setSelectedLanguage(countryLanguage);
  }, []);

  return (
    <>
      <Head>
        <title>{windowTitle}</title>
      </Head>
      <div className="min-h-screen bg-dark-gradient">
        <div className="min-h-screen bg-dots">
          <div className="px-4">
            <div className="flex flex-col max-w-md py-8 m-auto">
              <div className="flex items-center justify-between">
                <Logo width={104} fillText="white" />
                <div className="flex items-center space-x-2">
                  <CountryFlagLanguageSelector countryCode="ar" languageCode="es" />
                  <div className="w-px h-4 bg-blueGray500" />
                  <CountryFlagLanguageSelector countryCode="gb" languageCode="en" />
                </div>
              </div>
              <div className="px-6 pt-6 pb-6 mt-8 rounded-md md:px-12 md:pt-12 md:pb-10 bg-blueGray50">
                {children}
              </div>
              <div className="flex flex-auto mt-8 text-xs text-white item-center">
                {/* eslint-disable-next-line react-intl-sammy/missing-formatted-message */}
                <p className="mr-8">© Fonoa</p>
                <p>
                  <A className="text-xs" basic href="https://www.fonoa.com/privacy-notice">
                    <FormattedMessage
                      defaultMessage="Privacy"
                      description="Login Page > Link to Privacy Notice"
                      id="5LDAkq"
                    />
                  </A>
                  <span>{" & "}</span>
                  <A className="text-xs" basic href="https://www.fonoa.com/terms-of-service">
                    <FormattedMessage
                      defaultMessage="Terms"
                      description="Login Page > Link to Terms of Service"
                      id="1x6MfL"
                    />
                  </A>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

interface CountryFlagLanguageSelectorProps {
  countryCode: string;
  languageCode: Language;
}

const CountryFlagLanguageSelector = ({
  countryCode,
  languageCode,
}: CountryFlagLanguageSelectorProps) => {
  const { selectedLanguage, setSelectedLanguage } = useSelectedLanguage();

  return (
    <div
      data-cy={"btn-lang-" + languageCode}
      onClick={() => setSelectedLanguage(languageCode)}
      className={classNames("flex text-xs text-white cursor-pointer", {
        "opacity-100": selectedLanguage == languageCode,
        "opacity-50": selectedLanguage !== languageCode,
      })}
    >
      <CountryFlagLabel countryCode={countryCode} hideLabel />
      {/* eslint-disable-next-line react-intl-sammy/missing-formatted-message */}
      <p className="ml-2">{countryCode.toUpperCase()}</p>
    </div>
  );
};
