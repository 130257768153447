import Image from "next/image";

import getCountryFlagPath from "./CountryFlagPath";
import { useCountryOptions } from "./SelectOptions";

interface Props {
  countryCode: string;
  hideLabel?: boolean;
}

const CountryFlagLabel = ({ countryCode, hideLabel }: Props) => {
  const { countryOptions } = useCountryOptions();

  return (
    <div className="flex items-center">
      <Image
        width={16}
        height={16}
        src={getCountryFlagPath(countryCode, "1x1")}
        className="rounded-full"
        alt={`${countryCode} flag`}
      />
      {!hideLabel && (
        <span className="ml-2">{countryOptions.find((i) => i.value === countryCode)?.label}</span>
      )}
    </div>
  );
};

export default CountryFlagLabel;
